import React, { useState } from "react";
import {
  FaApple,
  FaHome,
  FaUserFriends,
  FaCommentDots,
  FaQuestionCircle,
  FaCog,
  FaLock,
  FaSignOutAlt,
  FaBars,
  FaSearch,
  FaEye,
  FaShoppingCart,
  FaCommentAlt,
  FaDollarSign,
} from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAppleAlt } from "@fortawesome/free-solid-svg-icons";
import "./style.css";

const SampleAdminDashboard: React.FC = () => {
  const [isActive, setIsActive] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const handleToggle = () => {
    setIsActive((prev) => !prev);
  };

  const handleMouseOver = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseOut = () => {
    setHoveredIndex(null);
  };

  const navItems = [
    { icon: <FaHome />, title: "Dashboard" },
    { icon: <FaUserFriends />, title: "Customers" },
    { icon: <FaCommentDots />, title: "Messages" },
    { icon: <FaQuestionCircle />, title: "Help" },
    { icon: <FaCog />, title: "Settings" },
    { icon: <FaLock />, title: "Password" },
    { icon: <FaSignOutAlt />, title: "Sign Out" },
  ];

  return (
    <div className="main-div">
      <div className={`containerss ${isActive ? "active" : ""}`}>
        {/* <div> */}
        <div className="navigation">
          <ul>
            <li>
              <a href="#">
                <span className="icon">
                  <FontAwesomeIcon icon={faAppleAlt} />
                </span>
                <span className="title">Brand Name</span>
              </a>
            </li>
            {navItems.map((item, index) => (
              <li
                key={index}
                className={hoveredIndex === index ? "hovered" : ""}
                onMouseOver={() => handleMouseOver(index)}
                onMouseOut={handleMouseOut}
              >
                <a href="#">
                  <span className="icon">{item.icon}</span>
                  <span className="title">{item.title}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div className={`main ${isActive ? "active" : ""}`}>
          <div className="topbar">
            <div className="toggle" onClick={handleToggle}>
              <FaBars />
            </div>
            <div className="search">
              <label>
                <input type="text" placeholder="Search here" />
                <FaSearch />
              </label>
            </div>
            <div className="user">
              <img src="assets/imgs/customer01.jpg" alt="User" />
            </div>
          </div>

          <div className="cardBox">
            <div className="card">
              <div>
                <div className="numbers">1,504</div>
                <div className="cardName">Daily Views</div>
              </div>
              <div className="iconBx">
                <FaEye />
              </div>
            </div>

            <div className="card">
              <div>
                <div className="numbers">80</div>
                <div className="cardName">Sales</div>
              </div>
              <div className="iconBx">
                <FaShoppingCart />
              </div>
            </div>

            <div className="card">
              <div>
                <div className="numbers">284</div>
                <div className="cardName">Comments</div>
              </div>
              <div className="iconBx">
                <FaCommentAlt />
              </div>
            </div>

            <div className="card">
              <div>
                <div className="numbers">$7,842</div>
                <div className="cardName">Earning</div>
              </div>
              <div className="iconBx">
                <FaDollarSign />
              </div>
            </div>
          </div>

          <div className="details">
            <div className="recentOrders">
              <div className="cardHeader">
                <h2>Recent Orders</h2>
                <a href="#" className="btn">
                  View All
                </a>
              </div>

              <table>
                <thead>
                  <tr>
                    <td>Name</td>
                    <td>Price</td>
                    <td>Payment</td>
                    <td>Status</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Star Refrigerator</td>
                    <td>$1200</td>
                    <td>Paid</td>
                    <td>
                      <span className="status delivered">Delivered</span>
                    </td>
                  </tr>
                  {/* Add other rows here */}
                </tbody>
              </table>
            </div>

            <div className="recentCustomers">
              <div className="cardHeader">
                <h2>Recent Customers</h2>
              </div>

              <table>
                <tr>
                  <td width="60px">
                    <div className="imgBx">
                      <img src="assets/imgs/customer02.jpg" alt="Customer" />
                    </div>
                  </td>
                  <td>
                    <h4>
                      David <br /> <span>Italy</span>
                    </h4>
                  </td>
                </tr>
                {/* Add other rows here */}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SampleAdminDashboard;
